import * as React from "react"
import { ApolloProvider } from "react-apollo"
import { ThemeProvider } from "gatsby-interface"
import createApolloClient from "@modules/graphql/client"

const isBrowser = () => typeof window !== `undefined`
/*
 * TODO: Ideally, we should just setup gatsbyjs.com like described here https://github.com/gatsbyjs/gatsby/issues/11225#issuecomment-457211628
 * However, the least potentially impactful solution is to just check if we are not on server side
 **/
const apolloClient = isBrowser() ? createApolloClient() : null

type ProvidersProps = {
  children: React.ReactNode
}

function Providers({ children }: ProvidersProps) {
  if (!isBrowser()) {
    return null
  }

  return (
    <ApolloProvider client={apolloClient} >
      <ThemeProvider>
        {children}
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default Providers
