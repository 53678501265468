import * as React from "react"

import Providers from "@modules/ui/layouts/Providers"
import { Router } from "@reach/router"

import { ContentLoader, ContentLoaderProps } from "./features/ContentLoader"

export default function(props: ContentLoaderProps) {
  return (
    <Providers>
      <Router>
        <ContentLoader
          {...props}
          path="/content-sync/:siteId/:sourcePluginName/:manifestId"
        />
        <ContentLoader
          {...props}
          path="/content-sync/:siteId/:sourcePluginName"
        />
        <ContentLoader {...props} path="/content-sync/:siteId" />
        <ContentLoader {...props} path="/content-sync" />
      </Router>
    </Providers>
  )
}
