import * as React from "react"

import { PageProps } from "gatsby"

import {
  Text,
  Link,
  MonogramIcon,
  useTheme,
  EmptyState,
  EmptyStateSecondaryAction,
  ThemeCss,
} from "gatsby-interface"

import Loading from "@modules/ui/components/Loading"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { previewLoader as text } from "@modules/locales/default"
import { useContentLoaderInfoQuery } from "@modules/graphql/queries.generated"
import { useTracker } from "@modules/analytics"

import { poll } from "./helpers"

import {
  PreviewIndicator,
  GatsbyIndicatorButton,
  LinkIndicatorButton,
  InfoIndicatorButton,
  BuildErrorIndicatorTooltip,
} from "gatsby-plugin-gatsby-cloud/components"

const { useEffect, Fragment } = React

export const errorMessages = {
  noPageFound: `Your Gatsby site didn't create a page for the content you're trying to view.`,
  misconfiguredUrl: `Your URL has been misconfigured. Ask a developer to check the console logs for more info.`,
}

const pollWaitTimeMs = 1000
const minutesUntilTimeout = 0.5

export type ContentLoaderProps = {
  manifestId: string
  siteId: string
  sourcePluginName: string
} & PageProps

export function ContentLoader(props: ContentLoaderProps) {
  const { manifestId, siteId, sourcePluginName } = props
  const { trackPageViewed, trackAction } = useTracker()

  const misconfiguredUrl = !manifestId || !siteId || !sourcePluginName

  const {
    data: apolloData,
    error: apolloError,
    refetch,
  } = useContentLoaderInfoQuery({
    variables: { siteId },
    skip: misconfiguredUrl,
  })

  const [loaderError, setLoaderError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(``)
  const [idleSince, setIdleSince] = React.useState<number | null>(null)

  const [pollCount, setPollCount] = React.useState(0)
  const [lastPollBuildStatus, setLastPollBuildStatus] = React.useState<
    string | null
  >(null)

  const triggerNextPoll = () => setPollCount(pollCount + 1)
  const waitThenTriggerNextPoll = () =>
    setTimeout(triggerNextPoll, pollWaitTimeMs)

  const statusHas = (state: string) => {
    if (apolloData?.contentLoaderInfo?.previewBuildStatus) {
      return apolloData?.contentLoaderInfo?.previewBuildStatus.includes(state)
    }
  }

  // only polling above 0 allows us to manually start polling.
  // The first useEffect run of poll will have pollCount set to 0.
  // Calling triggerNextPoll will increment it to 1 and then pollingHasStarted will be true.
  const pollingHasStarted = pollCount > 0

  const frontendUrl = apolloData?.contentLoaderInfo?.previewUrl || false

  /**
   * handleTimeout Handles updating timeout state and returns
   * true/false for whether or not the ui should timeout.
   */
  const handleTimeout = (): boolean => {
    // first check if we should timeout
    if (typeof idleSince === `number`) {
      const minutesSinceIdle = (Date.now() - idleSince) / 1000 / 60
      const shouldTimeout = minutesSinceIdle >= minutesUntilTimeout

      if (shouldTimeout) {
        console.warn(
          `Timed out waiting for node manifest. Builds are idle and no manifest was found after ${minutesSinceIdle} minutes.`
        )
        setLoaderError(true)
        return true
      }
    }

    const buildsAreCurrentlyIdle = statusHas(`ERROR`) || statusHas(`SUCCESS`)

    // check if we're idle and store the current timestamp if we are
    if (
      // if a build status exists
      apolloData?.contentLoaderInfo?.previewBuildStatus &&
      // and it's different than the last build status we stored
      lastPollBuildStatus !== apolloData.contentLoaderInfo.previewBuildStatus &&
      // and builds are idle
      buildsAreCurrentlyIdle
    ) {
      // store the time we started idling.
      const idleTimestamp = Date.now()
      console.info(
        `Setting idle time to now ${idleTimestamp} and last build status to ${apolloData.contentLoaderInfo.previewBuildStatus}`
      )
      setLastPollBuildStatus(apolloData.contentLoaderInfo.previewBuildStatus)
      setIdleSince(idleTimestamp)
    }
    // otherwise if we're not idle nullify the idleSince state so we don't timeout if we previously stored an idle start time timestamp
    else if (!buildsAreCurrentlyIdle) {
      console.info({
        buildStatus: apolloData?.contentLoaderInfo?.previewBuildStatus,
      })
      setIdleSince(null)
    }

    return false
  }

  useEffect(
    function handlePoll() {
      const shouldTimeout = handleTimeout()

      if (!shouldTimeout) {
        poll({
          misconfiguredUrl,
          loaderError,
          pollingHasStarted,
          manifestId,
          sourcePluginName,
          pollCount,
          apolloData,
          siteId,
          refetch,
          frontendUrl,
          waitThenTriggerNextPoll,
          setErrorMessage,
          setLoaderError,
          trackAction,
        })
      }
    },
    // whenever pollCount changes we re-run poll()
    [pollCount]
  )

  useEffect(
    function handleApolloErrors() {
      if (apolloError) {
        console.info(`Encountered apollo error`, apolloError)
        setLoaderError(true)
      }
    },
    [apolloError]
  )

  useEffect(
    function startPolling() {
      /**
       * When we first receive data from apollo we start polling
       */
      if (!pollingHasStarted && apolloData) {
        console.info(`Starting to poll for preview build updates`)
        triggerNextPoll()
      }
    },
    [apolloData]
  )

  useEffect(function onMountCheckForErrors() {
    if (misconfiguredUrl) {
      // @todo add link to docs for CMS/source-plugin authors
      console.error(
        `Missing part of content loader path. Please format your path like so /content-sync/:siteId/:sourcePluginName/:manifestId. See docs for more info.`
      )
      setLoaderError(true)
      setErrorMessage(errorMessages.misconfiguredUrl)
    } else {
      trackPageViewed(`Content Sync`, siteId)
    }
  }, [])

  return (
    <Fragment>
      {siteId && !misconfiguredUrl ? (
        <PreviewIndicator>
          <GatsbyIndicatorButton
            active={loaderError ? true : false}
            tooltipContent={
              loaderError ? (
                <BuildErrorIndicatorTooltip
                  siteId={siteId}
                  orgId={apolloData?.contentLoaderInfo?.orgId}
                  buildId={``}
                />
              ) : (
                text.messages.loadingTooltip
              )
            }
            overrideShowTooltip={true}
            showSpinner={loaderError ? false : true}
          />
          <LinkIndicatorButton
            active={false}
            siteId={siteId}
            orgId={``}
            buildId={``}
          />
          <InfoIndicatorButton
            active={false}
            siteId={siteId}
            orgId={``}
            buildId={``}
          />
        </PreviewIndicator>
      ) : null}
      {loaderError ? (
        <ErrorContent
          errorText={errorMessage}
          siteUrl={apolloData?.contentLoaderInfo?.previewUrl}
          orgId={apolloData?.contentLoaderInfo?.orgId}
          siteId={siteId}
        />
      ) : (
        <LoadingContent />
      )}
    </Fragment>
  )
}

export const footerCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `center`,
  width: `100%`,
  backgroundColor: theme.colors.ui.background,
})

export const errorContentCss: ThemeCss = () => ({
  display: `flex`,
  flexDirection: `column`,
  width: `100vw`,
  height: `100vh`,
})

export const graphicWrapperCss: ThemeCss = theme => ({
  backgroundColor: theme.colors.ui.background,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  flex: 1,
})

export const baseLinkCss: ThemeCss = () => ({
  textDecoration: `none`,
  margin: `0 0 0 7px`,
  cursor: `pointer`,
  alignItems: `flex-start`,
  "&:hover": {
    textDecoration: `none`,
  },
  "& > svg": {
    margin: `auto auto auto 5px`,
  },
})

function Footer() {
  const { colors } = useTheme()

  return (
    <div css={footerCss}>
      <Text size="S">Powered by</Text>
      <Link href={`https://gatsbyjs.com`} css={baseLinkCss}>
        <MonogramIcon
          color={colors.gatsby}
          style={{ marginTop: 0, marginRight: 5 }}
        />
        <Text variant="EMPHASIZED">Gatsby&nbsp;</Text>
        <Text tone="BRAND">Cloud</Text>
      </Link>
    </div>
  )
}

/**
 * Main content (image + text) if a build is in progress
 */
function LoadingContent() {
  const { colors } = useTheme()
  return (
    <Fragment>
      <Loading
        message={text.messages.loading}
        style={{ backgroundColor: colors.ui.background }}
      />
      <Footer />
    </Fragment>
  )
}

/**
 * Main content (image + text) if a build has errored
 */
function ErrorContent({
  siteUrl,
  errorText,
  orgId,
  siteId,
}: {
  siteUrl?: string | null
  errorText?: string
  orgId?: string
  siteId?: string
}) {
  const { trackAction } = useTracker()

  trackAction({
    eventType: `TRACK_EVENT`,
    name: `Content Sync Error`,
    uiSource: `Content Sync`,
    siteId,
  })
  // default error link
  const errorLink: { url: string; text: string } = {
    url:
      // if we have these
      orgId && siteId
        ? // we can send them to their site build list
          `${process.env.GATSBY_DASHBOARD_URL}/dashboard/${orgId}/sites/${siteId}/cmsPreview`
        : // otherwise send them to the dashboard
          `${process.env.GATSBY_DASHBOARD_URL}/dashboard`,

    text: orgId && siteId ? `View Error Logs` : `View Gatsby Cloud Dashboard`,
  }

  // override the default error link if there was no page found for the content the user is trying to view.
  if (siteUrl && errorText === errorMessages.noPageFound) {
    errorLink.url = siteUrl
    errorLink.text = `View Homepage`
  }

  return (
    <div css={errorContentCss}>
      <div css={graphicWrapperCss}>
        <EmptyState
          heading={text.headers.error}
          text={errorText || text.messages.error}
          graphic={<EmptyStateGraphic />}
          secondaryAction={
            <EmptyStateSecondaryAction href={errorLink.url}>
              {errorLink.text}
            </EmptyStateSecondaryAction>
          }
        />
      </div>
      <Footer />
    </div>
  )
}
